/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import Banner from '../components/home/banner';

import Squad from '../img/nathan.jpg'

function Matches() {
  return (
    <div>
      <Banner
        image={Squad}
        imageAlt="Bollington squad photo"
        heading='Our Journey so far'
        body='From conception to trophy triumph. Find out more about our journey, and plans for the future'
      />
          <div id="lrep776940976" style={{width: '350px'}}>Data loading....<a href="https://fulltime-league.thefa.com/index.html?divisionseason=685266626">click here for Division One</a><br/><br/><a href="http://www.thefa.com/FULL-TIME">FULL-TIME Home</a></div>

    </div>
  );
}

export default Matches;