/* eslint-disable react/prop-types */
import React from 'react';
import { saveAs } from 'file-saver';
import { FaFileDownload } from 'react-icons/fa';

import '../scss/components/card.scss';

const CardItem = ({ title, desc, url, text }) => {
  const handleDownload = () => {
    saveAs(url, `${title}.pdf`); // Adjust the file extension accordingly
  };

  return (
    <div className="card">
      <h3>{title}</h3>
      {!desc ?
      <>
        <FaFileDownload className="download-icon" />
        <button className="download-button" onClick={handleDownload}>
          Download
        </button>
      </> :
      <>
        <p>{desc}</p>
        <a className="download-button" href={url}>
          {text ? text : 'Email'}
        </a>
      </>
      }
    </div>
  );
};

export default CardItem;