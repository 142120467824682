import React from 'react';

import '../scss/pages/our-journey.scss';
import GetInvolved from './../components/home/get-involved';

function OurJourney() {
  return (
    <div>
      <section className="block-content t-block-teal l-block-spacing">
					<div className="l-contained">
						<header className="heading-group">
							<h1>Our Journey</h1>
							<p className="subtitle">
								BTFC Est. 2021
							</p>
						</header>
						<ul className="timeline-list">
							<li>
                  <div className="content">
								<h3>Where it all began</h3>
						
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut quam felis, rutrum nec enim non, sodales facilisis purus. Vestibulum viverra egestas ipsum eget commodo. Nullam aliquet lorem vitae nulla dictum vestibulum sed quis tellus. Sed diam diam, facilisis tincidunt volutpat nec, auctor quis magna. Proin sed nunc iaculis ipsum scelerisque tincidunt. Cras eleifend leo tellus, fermentum finibus tortor fringilla eu.
								</p>
                </div>
							</li>
							<li>
                  <div className="content">
								<h3>A league above</h3>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut quam felis, rutrum nec enim non, sodales facilisis purus. Vestibulum viverra egestas ipsum eget commodo. Nullam aliquet lorem vitae nulla dictum vestibulum sed quis tellus. Sed diam diam, facilisis tincidunt volutpat nec, auctor quis magna. Proin sed nunc iaculis ipsum scelerisque tincidunt. Cras eleifend leo tellus, fermentum finibus tortor fringilla eu.
								</p>
                </div>
							</li>
							<li>
                  <div className="content">
								<h3>New beginnings</h3>
							
						
								<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut quam felis, rutrum nec enim non, sodales facilisis purus. Vestibulum viverra egestas ipsum eget commodo. Nullam aliquet lorem vitae nulla dictum vestibulum sed quis tellus. Sed diam diam, facilisis tincidunt volutpat nec, auctor quis magna. Proin sed nunc iaculis ipsum scelerisque tincidunt. Cras eleifend leo tellus, fermentum finibus tortor fringilla eu.
								</p>
                </div>
							</li>
							<li>
                  <div className="content">
								<h3>Board additions</h3>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut quam felis, rutrum nec enim non, sodales facilisis purus. Vestibulum viverra egestas ipsum eget commodo. Nullam aliquet lorem vitae nulla dictum vestibulum sed quis tellus. Sed diam diam, facilisis tincidunt volutpat nec, auctor quis magna. Proin sed nunc iaculis ipsum scelerisque tincidunt. Cras eleifend leo tellus, fermentum finibus tortor fringilla eu.
								</p>
                </div>
							</li>
						</ul>
					</div>
				</section>
				<GetInvolved/>
    </div>
  );
}

export default OurJourney;