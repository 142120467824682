/* eslint-disable react/prop-types */
import React from 'react';
import CardItem from './card';

import '../scss/components/card.scss';

const CardRow = ({ cardData }) => {
  const createRowsOfThree = (data) => {
    const rows = [];
    for (let i = 0; i < data.length; i += 3) {
      const row = data.slice(i, i + 3);
      rows.push(row);
    }
    return rows;
  };

  const cardRows = createRowsOfThree(cardData);

  return (
    <div>
      {cardRows.map((row, rowIndex) => (
        <div key={rowIndex} className="card-row">
          {row.map((card, cardIndex) => (
            <CardItem key={cardIndex} text={card.text} title={card.title} desc={card.desc} url={card.url} />
          ))}
        </div>
      ))}
    </div>
  );
};

export default CardRow;