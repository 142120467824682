
import React  from 'react';
import PropTypes from 'prop-types';

import '../scss/components/row-content.scss';

const Row = ({
  image,
  imageAlt,
  heading,
  body,
  link,
  linkText,
  styleModifier,
  imageModifier
}) => {
  return (
    <section className={`container row-content ${styleModifier ? styleModifier : ''}`}>
      <div className="row-content__image-container">
        <img className={imageModifier} src={image} alt={imageAlt} />
      </div>
      <div className="row-content__text-container">
        <h1>{heading}</h1>
        <p className="row-content__text">{body}</p>
        { link ? <a className="button" href={link} rel="noreferrer" target="_blank">{linkText}</a> : null}
      </div>
    </section>
  );
}

Row.propTypes = {
  image: PropTypes.function,
  imageAlt: PropTypes.string,
  heading: PropTypes.string,
  body: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  styleModifier: PropTypes.string,
  imageModifier: PropTypes.string
}

export default Row;
