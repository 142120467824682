import React  from 'react';
import PropTypes from 'prop-types';

import { Link } from "react-router-dom";

import '../../scss/components/our-journey.scss';

const OurJourney = ({
  image,
  imageAlt,
  heading,
  body,
  buttonLink,
  buttonText
}) => {
  return (
    <section className="our-journey">
      <div className="our-journey__banner-div">
        <img className="our-journey__image" src={image} alt={imageAlt} />
      </div>
      <div className="our-journey__overlay"></div>
      <div className="our-journey__text-container container">
        <span className="banner-text">
          <h1>{heading}</h1>
          <p className="our-journey__text">{body}</p>
         { buttonLink && <Link className="button" to={buttonLink} target="_blank">{buttonText}</Link> }
        </span>
      </div>
    </section>
  );
}

OurJourney.propTypes = {
  image: PropTypes.function,
  imageAlt: PropTypes.string,
  heading: PropTypes.string,
  body: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string
}

export default OurJourney;
