import React  from 'react';

import '../../scss/components/next-match.scss';

const NextMatch = () => {
  return (
    <div className="next-match">
      <div className="next-match__wrapper container">
        <h2>NEXT MATCH</h2>
        <a className='next-match__button' href="https://fulltime.thefa.com/fixtures.html?selectedSeason=54628403&selectedFixtureGroupKey=1_80570566&selectedDateCode=all&selectedClub=&selectedTeam=312011849&selectedRelatedFixtureOption=3&selectedFixtureDateStatus=&selectedFixtureStatus=&previousSelectedFixtureGroupAgeGroup=&previousSelectedFixtureGroupKey=1_80570566&previousSelectedClub=&itemsPerPage=25" target='_blank' rel="noreferrer">View our next match</a>
      </div>
    </div>
  );
}

export default NextMatch;
