import React from 'react';

import OurJourney from '../components/home/banner';
import NextMatch from './../components/home/next-match';
import GetInvolved from './../components/home/get-involved';
import Row from './../components/row-content';

import '../scss/components/instagram.scss';
import Squad from '../img/squad.jpg'
import Brown from '../img/brown.jpeg'
import Poster from '../img/poster.jpg'

import data from '../data/about-us.json';

function Home() {
  return (
    <div>
      <OurJourney
        heading='Our Journey so far'
        body='From conception to trophy triumph. Find out more about our journey, and plans for the future'
        buttonLink='mailto:info@bollingtontownfc.co.uk'
        buttonText='Find out more'
        image={Squad}
        imageAlt='Bollington Town Squad Photo'
      />
      <Row
        image={Poster}
        imageAlt='Under 23s team poster'
        heading="Bollington Town Development side announced!"
        imageModifier='small'
        link='mailto:chairman@bollingtontownfc.co.uk'
        linkText='Contact us'
        body={data.contactUs}/>
      <NextMatch/>
      <div className="container">
        <div id="curator-feed-default-feed-layout"><a href="https://curator.io" target="_blank" className="crt-logo crt-tag" rel="noreferrer"></a></div>
      </div>
      <GetInvolved/>
      <Row
        image={Brown}
        imageAlt='Chairman, Josh Brown lifting the league trophy'
        heading='Bollington Town FC'
        styleModifier='reverse'
        body={data.body}/>
    </div>
  );
}

export default Home;