import React from 'react';

import Row from '../components/row-content';
import Map from '../components/map';
import CardRow from './../components/card-row';

import AwayShirt from '../img/jake-nathan.jpg';

import '../scss/pages/get-involved.scss';

function GetInvolved() {
  const cardData = [
    { title: 'Jake Eastham', desc: 'President', url: 'mailto:treasurer@bollingtontownfc.co.uk' },
    { title: 'Josh Brown', desc: 'Chairman', url: 'mailto:chairman@bollingtontownfc.co.uk' },
    { title: 'Zach Gatward', desc: 'Vice Chairman', url: 'mailto:info@bollingtontownfc.co.uk' },
    { title: 'Declan Hanks', desc: 'Secretary', url: 'mailto:secretary@bollingtontownfc.co.uk' },
    { title: 'Sam Glover', desc: 'Treasurer', url: 'mailto:samjg11@hotmail.com' },
    { title: 'Jeff Eastham', desc: 'First Team Manager', url: 'mailto:info@bollingtontownfc.co.uk' },
    { title: 'Open Position', desc: 'Second Team Manager', url: 'mailto:info@bollingtontownfc.co.uk', text: 'Enquire here' },
    { title: 'Jamie Devonport', desc: 'Welfare Officer/Assistant Secretary', url: 'mailto:info@bollingtontownfc.co.uk' },
    { title: 'Chris Bennett', desc: 'Commercial Officer', url: 'mailto:info@bollingtontownfc.co.uk' }

  ];
  return (
    <div>
      <Map/>
      <Row
        image={AwayShirt}
        imageAlt="Bollington Town Fcs new away shirt"
        heading="Get in touch"
        body="Interested in getting involved with a community football club in the heart of Bollington? For any queries, do not hesitate to get in contact with us using the links provided below."
        styleModifier='get-involved'
      />
      <CardRow cardData={cardData} />
    </div>
  );
}

export default GetInvolved;