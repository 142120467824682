import React  from 'react';
import { Link } from "react-router-dom";

import '../../scss/components/next-match.scss';

const GetInvolved = () => {
  return (
    <div className="next-match">
      <div className="next-match__wrapper">
        <h2>Get Involved</h2>
        <p>Interested in playing, coaching, becoming a sponsor or anything in between?</p>
        <Link className="button btn-secondary" to="/get-involved">Get involved</Link>
      </div>
    </div>
  );
}

export default GetInvolved;  
