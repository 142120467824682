import React from 'react';


function Squad() {
  return (
    <div>
    </div>
  );
}

export default Squad;