import React  from 'react';
import { Link } from "react-router-dom";

import Logo from '../img/logo.png';
import Facebook from '../img/facebook.png';
import Twitter from '../img/twitter.png';
import Instagram from '../img/instagram.png';

import '../scss/components/footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__wrapper container">
        <Link to='/'><img className="footer__logo" src={Logo} alt="Bollington Town FC logo"></img></Link>
        <p>Bollington Town Football Club</p>
        <div className="footer__socials-wrapper">
          <a href="https://www.instagram.com/bollingtontownfc/" target="_blank" rel="noreferrer"><img src={Instagram} alt="Instagram"></img></a>
          <a href="https://www.facebook.com/Bollington-Town-Football-Club-107699561394653" target="_blank" rel="noreferrer"><img src={Facebook} alt="Facebook"></img></a>
          <a href="https://twitter.com/BollingtonTown" target="_blank" rel="noreferrer"><img src={Twitter} alt="Twitter"></img></a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
