import React from 'react';


function Community() {
  return (
    <div>
    </div>
  );
}

export default Community;