import React from 'react';

import '../scss/pages/get-involved.scss';
import '../scss/pages/club-documents.scss';

import CardRow from './../components/card-row';

function GetInvolved() {
  const cardData = [
    { title: 'Club Constitution', url: '/pdfs/constitution.pdf' },
    { title: 'FA Code of Conduct - Coaches and Managers', url: '/pdfs/fa-respect-code-of-conduct-coaches-managers-club-officials.pdf' },
    { title: 'FA Code of Conduct - Adult Players', url: '/pdfs/fa-respect-code-of-conduct-adult-players.pdf' },
    { title: 'FA Code of Conduct - Match Officials', url: '/pdfs/fa-respect-code-of-conduct-match-officials.pdf' },
    { title: 'FA Code of Conduct - Spectators and Parents', url: '/pdfs/fa-respect-code-of-conduct-spectators-parents.pdf' },
    { title: 'FA Code of Conduct - Young Players', url: '/pdfs/fa-respect-code-of-conduct-young-players.pdf' },
    { title: 'BTFC - Anti-Bullying Policy', url: '/pdfs/BTFC-Anti-Bullying-Policy.pdf' },
    { title: 'BTFC - Social Media Policy', url: '/pdfs/BTFC-Social-Media-Policy.pdf' },
    { title: 'BTFC - Drug and Alcohol Policy', url: '/pdfs/BTFC-club-drug-and-alcohol-policy.pdf' },
    { title: 'BTFC - Data Policy', url: '/pdfs/BTFC-Data-Policy.pdf' }

    // Add more card data as needed
  ];
  return (
    <div className='club-documents'>
      <h1>Club Documents</h1>
      <CardRow cardData={cardData} />
    </div>
  );
}

export default GetInvolved;