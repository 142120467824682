import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Home from './pages/home'
import Squad from './pages/squad'
import Matches from './pages/matches'
import Community from './pages/community'
import GetInvolved from './pages/get-involved'
import OurJourney from './pages/our-journey'
import ClubDocuments from './pages/club-documents'
import Sponsors from './components/home/sponsor';

import Header from './components/header';
import Footer from './components/footer';

export default function App() {
  return (
    <main>
      <Router>
        <Header/>
        <div>
          <Switch>
            <Route path="/matches">
              <Matches />
            </Route>
            <Route path="/squad">
              <Squad />
            </Route>
            <Route path="/community">
              <Community />
            </Route>
            <Route path="/get-involved">
              <GetInvolved />
            </Route>
            <Route path="/our-journey">
              <OurJourney />
            </Route>
            <Route path="/club-documents">
              <ClubDocuments />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
        <Sponsors/>
        <Footer/>
      </Router>
    </main>
  );
}
