import React from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';

import '../scss/map.scss';

const Map = () => {
  const mapboxAccessToken = 'pk.eyJ1IjoiamFrZWVhc3RoYW05OSIsImEiOiJjbGt6YnYzbHgwaDk4M2VxcGo1aWQ2amNqIn0.0gQlumjTgBuWZGI3nFzHZw'; // Replace with your actual Mapbox access token

  const mapStyle = 'mapbox://styles/mapbox/streets-v11';
  const latitude = 53.256608;
  const longitude = -2.141576;
  const zoom = 15;

  return (
    <ReactMapGL
      width="100%"
      height="800px"
      latitude={latitude}
      longitude={longitude}
      zoom={zoom}
      mapStyle={mapStyle}
      mapboxAccessToken ={mapboxAccessToken}
    >
      <Marker latitude={latitude} longitude={longitude}>
        <div style={{ fontSize: '24px' }}>Your Marker</div>
      </Marker>
    </ReactMapGL>
  );
};

export default Map;