import React  from 'react';

import '../../scss/components/sponsor.scss';

import MeelGroup from '../../img/sponsors/meelGroup.png';
import BollyFest from '../../img/sponsors/BollyFestivalLogo.png';
import Infinity from '../../img/sponsors/infinityShirts.png';
import Anita from '../../img/sponsors/anitaD.png';

const Sponsor = () => {
  return (
    <section className="sponsors">
      <div className="sponsors__container">
        <a href="https://meelgroup.co.uk/">
          <img src={MeelGroup} alt="MeelGroup logo"></img>
        </a>
        <a href="https://bollingtonfestival.org.uk/">
          <img src={BollyFest} alt="BollyFest logo"></img>
        </a>
        <a href="https://infinityfootballshirts.com/">
          <img src={Infinity} alt="Infinity football shirts logo"></img>
        </a>
        <a href="https://www.travelcounsellors.co.uk/anita.devonport/">
          <img src={Anita} alt="Anita Devonport Travel logo"></img>
        </a>
      </div>
    </section>
  );
}

export default Sponsor;
